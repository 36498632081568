/**
 * Error Report Form Logic
 *
 * Views: base/main base/error
 */

$(document).ready(function(){
   /* var app_error_report_form = $('form#app-error-report-form');
    if(app_error_report_form.length > 0){
        app_error_report_form.on('submit', function(e){
            e.preventDefault();

            var data = $(this).serializeArray();

            $.ajax({
                type: 'POST',
                url: '/help/errors/report',
                data: data,
                dataType: 'json',
                encode: true,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data) {
                    global_notification('success', 'Your error has been reported.');
                    $('#errorReportModal').modal('hide');
                    hide_loader();
                },
                error: function (xhr) {
                    ajax_error();
                    debug(xhr.responseText);

                    hide_loader();
                }
            });
        });
    }*/
    $('.send_atta').on('click', function () {
        show_overlay_loader( $('#errorReportModal .modal-content'));
        var file1 = $("#image_1");
        var file2 = $("#image_2");
        var file3 = $("#image_3");

        var formData = new FormData();
        if(file1 !== '')
        formData.append('image_1', file1[0].files[0]);

        formData.append('image_2', file2[0].files[0]);
        formData.append('image_3', file3[0].files[0]);
        formData.append('error_description', $('#error_description').val());
        formData.append('priority', $('#error_priority option:selected').val());
        formData.append('error_recurring', $('#error_recurring').val());

        $.ajax({
            type: 'POST',
            url: '/help/errors/report',
            dataType: 'json',
            data: formData,
            processData: false,
            contentType: false,
            enctype: 'multipart/form-data',
            async: true,
            cache: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                global_notification('success', 'Your error has been reported.');
                $('#errorReportModal').modal('hide');
                $('.dropify-clear').click();
                hide_overlay_loader( $('#errorReportModal .modal-content'));
            },
            error: function (xhr) {
                ajax_error();
                debug(xhr.responseText);
                hide_overlay_loader( $('#errorReportModal .modal-content'));
            }
        });
    });

});
