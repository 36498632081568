var app_ajax_form = '[data-ajax-form]';

if ($(app_ajax_form).length) {
    $(document).on('submit', app_ajax_form, function (e) {
        e.preventDefault();

        if (!$(this).valid()) {
            $(this).data('validator').focusInvalid();
        } else {
            if($(this).attr('id')==="app-campaigns-form"){

            }
            show_loader();
            if($(this).attr('id')==='app-orders-create-form')
                var order_form=true;
            else
                var order_form=false;

            var data = $(this).serializeArray();
            var url = $(this).attr('data-ajax-form');
            var form = $(this);

            $(this).find('.form-errors').slideUp();

            $.ajax({
                type: 'POST',
                url: url,
                data: data,
                dataType: 'json',
                encode: true,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data) {
                    console.log(data);

                    if(typeof(data.error) === 'boolean'){
                        global_notification('warning', data.message);
                        hide_loader();
                    }else {
                        global_notification('success', $(form).attr('data-ajax-form-success'));

                        if($(form).attr('data-ajax-form-success') === "Order created.")
                            $('#template_name').val('');

                        if($(form).attr('data-ajax-form-table') === "#app-vendors-table")
                            $('select.system_select option').remove()

                        if($(form).attr('data-ajax-form-success') === "Campaign updated."){
                            console.log("INSIDE");
                            $(".open_markets").each(function(){
                                console.log("INSIDE 2");
                                var adv = $(this).attr('data-adv');
                                var camp = $(this).attr('data-camp');
                                var ver = $(this).attr('data-ws');
                                var markets = data.markets;

                                var html = '';
                                $.each(markets, function(ind, val){
                                    html += '<li><a href="/adv/'+ adv +'/cam/'+ camp +'/ws/v/'+ ver +'/'+ val.slug +'">'+ ((val.type !== "") ? val.type.toUpperCase() : "CUSTOM") + '-' + val.name +'</a></li>';
                                });
                                console.log(html);
                                $(this).html(html);

                            });
                        }
                        if($(form).attr('id') ==='app-user-edit-form'){
                            location.reload();
                        }
                        if($(form).attr('id') ==='app-orders-create-form'){
                            $(".previewOrderModal .modal-body #v-pills-tabContent, .previewOrderModal .modal-body #v-pills-tab").html("")
                            for(var x in data.revisions_ids){
                                if($('#app-orders-create-form #only_preview').length === 1 && $('#app-orders-create-form #only_preview').val() == 1){
                                    if($(".previewOrderModal").length === 1){
                                        var active_var = (x==0) ? "active" : ""
                                        $(".previewOrderModal .modal-body #v-pills-tabContent").append('<div class="tab-pane  ' + active_var + '  show" id="revision-' + data.revisions_ids[x] + '" role="tabpanel"><div class="row preview-order preview-order-' + data.revisions_ids[x] + '"></div></div>')
                                        $(".previewOrderModal .modal-body #v-pills-tab").append('<li class="nav-item ' + active_var + '"> <a class="nav-link show" data-toggle="tab" href="#revision-' + data.revisions_ids[x] + '" role="tab" aria-selected="true"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">' + data.vendors[x].toUpperCase() + '</span> </a> </li>')
                                        if(x == 0) {
                                            $(".preview-order-" + data.revisions_ids[x]).load('/order/' + data.revisions_ids[x] + '/download/1', function () {
                                                setTimeout(function () {
                                                    hide_overlay_loader(".previewOrderModal .modal-lg")
                                                }, 1000)
                                            });
                                        }else{
                                            $(".preview-order-" + data.revisions_ids[x]).load('/order/' + data.revisions_ids[x] + '/download/1')
                                        }
                                    }
                                }else{
                                    var url= '/order/'+ data.revisions_ids[x]+'/download';
                                    window.open(url)
                                }
                            }
                            if($(".previewOrderModal").length === 1 && $('#app-orders-create-form #only_preview').length === 1 && $('#app-orders-create-form #only_preview').val() == 1){
                                $('.previewOrderModal').on('shown.bs.modal', function () {
                                    show_overlay_loader(".previewOrderModal .modal-lg")
                                })
                                $(".previewOrderModal").modal('show');
                            }
                        }

                        if(order_form===true ){
                            $('#app-orders-create-form #only_preview').val(0);
                            $('#app-orders-create-form').attr('data-ajax-form-success', 'Order created.');
                        }

                        if($(form).attr('id') ==='app-orders-create-form')
                            InizialiDataTableTemplate();
                        if (!!form.attr('data-ajax-form-reset')) {
                            resetForm(form);
                            form.closest('.panel').find('div.clickable').click();
                            if (!!form.attr('data-ajax-brand-product')) {
                                reset_brands_products();
                            }
                        }
                        if (!!form.attr('data-ajax-form-table')) {
                            var table = $(form.attr('data-ajax-form-table')).DataTable().ajax.reload();
                        }
                        if (typeof data.new_tab_target !== 'undefined') {
                            window.open(data.new_tab_target);
                        }
                        hide_loader();
                        if(typeof data.data !== 'undefined') {
                            if (typeof data.data.id !== 'undefined' && typeof data.data.adv_fwd !== 'undefined' && data.data.adv_fwd == '1') {
                                if (data.data.entity == 'advertisers') {
                                    window.location.href = '/' + data.data.entity + '/' + data.data.slug;
                                }
                                if (data.data.entity == 'campaigns') {
                                    if (data.data.adv_slug) {
                                        window.location.href = '/advertisers' + '/' + data.data.adv_slug + '/campaigns/' + data.data.slug;
                                    }
                                }
                            }
                            if (typeof data.data.id !== 'undefined' && typeof data.data.adv_fwd !== 'undefined' && data.data.adv_fwd == '0') {
                                if (data.data.entity == 'campaigns') {
                                    if (data.data.adv_slug) {
                                        window.location.href = '/advertisers' + '/' + data.data.adv_slug ;
                                    }
                                }
                            }
                            if($(form).attr('data-ajax-form-success') === "Campaign updated."){
                                if(typeof data.redirect !== 'undefined')
                                    window.location.href = data.redirect;
                            }
                            if($(form).attr('data-ajax-form-success') === "Advertiser updated."){
                                if(typeof data.redirect !== 'undefined')
                                    window.location.href = data.redirect;
                            }
                            if($(form).attr('data-ajax-form-success') === "Vendor updated."){
                                if(typeof data.redirect !== 'undefined')
                                    window.location.href = data.redirect;
                            }

                            if($(form).attr('data-ajax-form-success') === "Vendor Contact updated."){
                                if(typeof $(form).attr('data-ajax-form-redirect') !== 'undefined')
                                    window.location.href = $(form).attr('data-ajax-form-redirect');
                            }
                        }
                    }
                    if($(form).attr('data-ajax-form-success') === "Vendor created!"){
                        $("#wsAddVendor").modal("hide");
                        show_loader();
                        init_progress_loader(2, "Refreshing Worksheet! Please wait a few seconds...");
                        location.reload();
                    }
                },
                error: function (xhr) {
                    console.log(xhr);
                    debug(xhr.responseText);
                    ajax_error();
                    if (xhr.status == 422) {
                        var issues = xhr.responseJSON;
                        var error_text = '<ul>';
                        for (var index in issues) {
                            error_text = error_text + '<li>' + issues[index] + '</li>';
                        }
                        error_text = error_text + '</ul>';
                        form.find('div.form-errors .issues').html(error_text);
                        form.find('.form-errors').slideDown();
                    } else {
                        debug(xhr.responseText);
                        ajax_error();
                    }
                    hide_loader();
                }
            });
        }
    });
}


function resetForm(form) {
    form.find('input:text, input:password, input:file, select, textarea').val('');
    form.find('input:radio, input:checkbox')
        .removeAttr('checked').removeAttr('selected');
    if (!!form.attr('data-form-wizard')) {
        $(form.attr('data-form-wizard')).data('bootstrapWizard').first();
        form.find('.nav.nav-pills li').removeClass('tab-current');
        form.find('.nav.nav-pills li:first').addClass('tab-current');
        form.find('.tab-content .tab-pane').removeClass('content-current');
        form.find('.tab-content .tab-pane:first').addClass('content-current');
    }
    if ($('.select2-selection__choice').length)
        $('.select2-selection__choice').remove();
    form.find('.jquery-multiselect').multiSelect('refresh');

    // Clear flight dates form
    if($('#app-campaigns-form-wizard').length){
        $('#flight_weeks_inputs').html('');
        $('#flight_weeks_container').html('<div class="help-text text-center">Please input a Date Range to set this Campaign\'s Flight Weeks.</div>')
    }
}